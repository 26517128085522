@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

html {
  box-sizing: border-box;
  font-size: 10px;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

// ***** PC START ***** //

body {
  // background: #FCF5EE;
}

.container {
  font-family: 'Quicksand', sans-serif;
  width: 100%;
  // padding: 0 50px;
}

.headerCon {
  width: 100%;
  background-color: #FCF5EE;
}


.navigation {
  // padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 80px;
  border-bottom: 1px solid rgba(72, 72, 72, 0.476);
  // text-align: center;
  // background-color: #FCF5EE;
  position: relative;

  .headerLogo {
    position: absolute;
    font-family: 'Dancing Script', cursive;
    text-decoration: none;
    text-transform: none;
    color: black;
    left: 0;

    h1 {
      font-size: 3vw;
      font-weight: 500;
    }
  }

  ul {
    text-align: center;
  }

  li {
    list-style-type: none;
    display: inline-block;
  }

  a {
    text-decoration: none;
    color: black;
    font-size: calc(.7em + .7vw);
    text-transform: uppercase;
    display: block;
    padding: 10px 20px;
  }

  .bookTid {
    background: rgba(59, 59, 59, 0.493);
    position: absolute;
    border-radius: 5px;
    right: 0;

    &:hover {
      background-color: black;
    }
    
    a {
      color: white;
    }

    span {
      position: relative;
      display: block;
      cursor: pointer;
      padding-right: 5px;
    }
    
    span:before, span:after {
      content: '';
      position: absolute;
      width: 0%;
      height: 1px;
      top: 50%;
      margin-top: -0.5px;
      background: white;
    }
    
    span:before {
      left: -2.5px;
    }
    span:after {
      right: 2.5px;
      background: white;
      transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
    }
    
    &:hover span::before {
      background: white;
      width: 100%;
      transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    }
    
    &:hover span::after {
      background: transparent;
      width: 100%;
      transition: 0s;
    }
  }

  span {
    position: relative;
    display: block;
    cursor: pointer;
    padding-right: 5px;
  }
  
  span:before, span:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    top: 50%;
    margin-top: -0.5px;
    background: #000;
  }
  
  span:before {
    left: -2.5px;
  }
  span:after {
    right: 2.5px;
    background: #000;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  }
  
  li:hover .spanHover::before {
    background: #000;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }
  
  li:hover .spanHover::after {
    background: transparent;
    width: 100%;
    transition: 0s;
  }

  .activeClassName {
    position: relative;

    .spanHover {
      position: relative;
      display: block;
      cursor: pointer;
      padding-right: 5px;
    }
    
    .spanHover:before, .spanHover:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      margin-top: -0.5px;
      background: #000;
      left: -2.5px;
      right: 2.5px;
    }
  }

  #navPriser:hover > .dropdown {
    display: block;
  }

  .dropdown {
    position: absolute;
    display: none;
    background-color: white;
    width: calc(3em + 3vw);
    text-align: left;
    z-index: 999;

    a {
      display: block;
      font-size: calc(.5em + .5vw);
      border-bottom: 1px solid black;
      padding: 10px 5px;
      width: 100%;
    }

    span {
      width: fit-content;
    }

    li {
      width: 100%;

      &:hover {
        background-color: rgb(146, 146, 146);
      }
    }
  }
}

.homeCon {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: #FCF5EE;
  height: min-content;
  // padding-bottom: 60px;
  padding: calc(1.5em + 1.5vw);

  .homeContentInfoCon {
    grid-column: 1/7;
    margin-top: calc(5em + 5vw);
    margin-left: calc(1.5em + 1.5vw);
    text-align: center;

    .homeContentHeaderOne {
      position: relative;
      font-weight: 500;
      font-size: calc(1.5em + 1.5vw);
      text-transform: uppercase;
      border-bottom: 1px solid rgba(72, 72, 72, 0.476);
      padding: 10px 0;

      span {
        position: absolute;
        content: '';
        // padding-left: calc(2em + 2vw);
        // margin-left: calc(.5em + .5vw);
        border-right: 1px solid rgba(72, 72, 72, 0.476);
        border-top: 1px solid rgba(72, 72, 72, 0.476);
        right: 10%;
        bottom: 0;
        height: 100%;
        width: 15%;
      }
    }

    .homeContentHeaderTwo {
      position: relative;
      font-weight: 500;
      font-size: calc(1.5em + 1.5vw);
      text-transform: uppercase;
      // border-top: 1px solid black;
      padding: 10px 0;

      span {
        position: absolute;
        content: '';
        // padding-right: calc(2em + 2vw);
        // margin-right: calc(.5em + .5vw);
        border-left: 1px solid rgba(72, 72, 72, 0.476);
        border-bottom: 1px solid rgba(72, 72, 72, 0.476);
        left: 10%;
        top: 0;
        height: 100%;
        width: 15%;
      }
    }

    button {
      width: calc(4.5em + 4.5vw);
      height: 50px;
      margin: calc(2.5em + 2.5vw) calc(.3em + .3vw);
      text-transform: uppercase;
      text-align: center;
      font-size: calc(.5em + .5vw);
      font-weight: bold;
      cursor: pointer;
      border: solid 1px rgba(72, 72, 72, 0.476)
    }

    .homeContentBook {
      background-color: black;
      color: white;
    }

    .homeContentKontakt {
      background-color: white;
      color: black;
    }
  }

  .kontaktInfoCon {
    grid-column: 1/7;
    text-align: center;

    p {
      display: inline-block;
      font-size: .69vw;
      margin: 0 calc(.2em + 2vw);

      a {
        color: black;

        &:hover {
          color: rgb(78, 78, 78);
        }
      }
    }

    span {
      font-weight: bold;
    }
  }

  .homeContentImgConPLUS {
    grid-column: 7/13;
    grid-row: 1;
    position: relative;
    margin: 0 calc(1.5em + 1.5vw);
    text-align: center;
    padding: 0 100px;
    height: 100%;

    .homeContentImgCon {
      background-color: white;
      position: absolute;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    }
    img {
      width: 100%;
      position: relative;
      // right: calc(1.5em + 1.5vw);
      // top: calc(1.5em + 1.5vw);
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
  }  
}

.homeAboutCon {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: calc(1.5em + 1.5vw);
  padding-bottom: calc(1.5em + 1.5vw);

  .homeAboutHeader {
    position: relative;
    justify-self: center;
    text-transform: uppercase;
    grid-column: 1/13;
    font-size: calc(1.3em + 1.3vw);
    font-weight: 500;
    padding: 15px 30px;
    width: max-content;

    span {
      position: absolute;
      content: '';
      // padding-left: calc(2em + 2vw);
      // margin-left: calc(.5em + .5vw);
      border-right: 1px solid rgba(72, 72, 72, 0.476);
      border-top: 1px solid rgba(72, 72, 72, 0.476);
      right: 0;
      bottom: 0;
      height: 100%;
      width: 50%;
    }
  }

}
.priserHeader {
  grid-column: 1/4;
  text-align: center;
  font-size: calc(1.4em + 1.4vw);
  text-transform: uppercase;
  background-color: #FCF5EE;
  padding-top: calc(.5em + .5vw);
}

.priserCon {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // grid-template-rows: minmax(auto, max-content);
  gap: calc(1.5em + 1.5vw);
  padding: 0 10vw;
  padding-top: calc(1.5em + 1.5vw);
  // opacity: 0;
  background-color: #FCF5EE;
  position: relative;

  .priserConBorder {
    content:'';
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    height: 10%;
    width: 8%;
    margin: 0 7vw;
    border-top: 1px solid black;
    border-left: 1px solid black;
  }

  h3 {
    font-size: calc(.8em + .8vw);
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .indivPrisCon {
    border-top: 1px solid black;
    display: grid;
    grid-template-columns: fit-content(100%) 1fr;
    padding: 10px 0;
  }

  #indivPrisLastCon, .indivPrisLastCon {
    border-bottom: 1px solid black;
    margin-bottom: 10px;
  }

  p {
    font-size: calc(.6em + .6vw);
  }

  .pris {
    text-align: right;
  }

  .priserContentCon {
    height: min-content;
    padding: calc(1em + 1vw);
    margin-bottom: calc(1.5em + 1.5vw);
    background-color: #fffaf4;
    // background-color: #faf9f9;
    // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.galleryH2 {
  text-align: center;
  font-size: calc(1.4em + 1.4vw);
  text-transform: uppercase;
  background-color: #FCF5EE;
  padding-top: calc(.5em + .5vw);
}

.galleryCon {
  background-color: #FCF5EE;
  padding: 30px 80px;
  // text-align: center;
  display: flex;
  justify-content: center;
  
  .galleryImages {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    width: 100%;
  }

  img {
    margin-bottom: 30px;
    width: 100%;
    align-self: baseline;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
  
  ul {
    list-style: none;
  }

  .galleryImages > li {
    flex: 1 1 auto; /* or flex: auto; */
    height: 300px;
    cursor: pointer;
    position: relative;
  }

  .galleryImages li img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 5px;
  }

  .galleryImages::after {
    content: "";
    flex-grow: 999;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(57, 57, 57, 0.502);
    top: 0;
    left: 0;
    transform: scale(0);
    transition: all 0.1s 0.1s ease-in-out;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .galleryImages li:hover .overlay {
    transform: scale(1);
  }
}

.footerBGCcon {
  background-color: #FCF5EE;
}

.footerCon {
  background-color: #FCF5EE;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 80px;
  border-top: 1px solid rgba(72, 72, 72, 0.476);

  .footerLogo {
    grid-column: 1/13;
    font-family: 'Dancing Script', cursive;
    text-decoration: none;
    text-transform: none;
    color: black;
    padding-bottom: 20px;
    width: fit-content;
    
    h1 {
      font-size: 3vw;
      font-weight: 500;
    }
  }

  .addressCon {
    grid-column: 1/3;
    p {
      font-size: .8vw;
      margin-bottom: .15vw;
    }
  }

  .tiderCon {
    grid-column: 6/8;
    font-size: .8vw;

    .indivTiderCon {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      p {
        line-height: 20px;
      }

      .tid {
        text-align: left;
      }
    }
  }
}

// ***** PC END ***** //